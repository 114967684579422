<template>
  <div class="about">
    Glossi.fi was founded in 2019 by <a href="https://www.linkedin.com/in/donald-josey-aaa3a8191/">Don Josey</a> and <a href="https://www.linkedin.com/in/edward-c-newman/">Eddie Newman</a>.
  </div>
</template>

<script>
export default {
  name: 'About',

}
</script>

<style scoped>

</style>
